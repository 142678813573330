var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sales-category-setting"},[_c('PageTitle',{attrs:{"title":"點數卡設定","btn":"新增"},on:{"btnClick":function($event){return _vm.$router.push({ name: 'CreatePointCard' })}}}),_c('FiltersContainer',[_c('BaseElInput',{attrs:{"testName":"search_name","clearable":"","placeholder":"搜尋點數卡名稱"},on:{"clear":function($event){return _vm.refresh(true)}},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refresh(true)}},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.refresh(true)}},slot:"suffix"})])],1),_c('section',[_c('BaseTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.table),expression:"loading.table"}],attrs:{"data":_vm.displayData,"empty-text":"暫無數據","testName":"pointCardSetting"}},[_c('EmptyBlock',{attrs:{"slot":"empty"},slot:"empty"}),_c('BaseElTableColumn',{attrs:{"prop":"name","label":"名稱","align":"center"}}),_c('BaseElTableColumn',{attrs:{"prop":"totalAmount","label":"點數數量","align":"center"}}),_c('BaseElTableColumn',{attrs:{"prop":"exp","label":"有效期限","align":"center"}}),_c('BaseElTableColumn',{attrs:{"prop":"order","label":"排序","align":"center"}}),_c('BaseElTableColumn',{attrs:{"label":"操作","fixed":"right","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('TableEditBtnGroup',{attrs:{"testName":`pointCard_${scope.row.name}`,"hideCopy":false},on:{"edit":function($event){return _vm.$router.push({
                name: 'CreatePointCard',
                params: { pointCardId: scope.row.id },
              })},"copy":function($event){return _vm.$router.push({
                name: 'CreatePointCard',
                params: { pointCardId: scope.row.id },
                query: { copy: true },
              })},"delete":function($event){;(_vm.deleteDialog = true), (_vm.selectRow = scope.row)}}})]}}])})],1),_c('Pagination',{attrs:{"curPage":_vm.tableOptions.page,"pageLimit":_vm.tableOptions.pageLimit,"total":_vm.tableDataCount},on:{"update:curPage":function($event){return _vm.$set(_vm.tableOptions, "page", $event)},"update:cur-page":function($event){return _vm.$set(_vm.tableOptions, "page", $event)},"pageChange":function($event){return _vm.refresh(false)}}})],1),(_vm.deleteDialog)?_c('DeleteDialog',{attrs:{"title":"提醒","content":"刪除後將無法復原，確定要刪除？","width":"40%","btnString":"刪除"},on:{"close":function($event){_vm.deleteDialog = false},"delete":function($event){_vm.deletePointCard(), (_vm.deleteDialog = false)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }